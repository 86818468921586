import React from "react";
import PageHeader from "../PageHeader/PageHeader";
import "./ManagementComponent.css";

class ManagementComponent extends React.Component {
  render() {
    return (
      <>
        <PageHeader pagename="Management" />
        <div id="paper">
          <div id="pattern">
            <div id="content">
              Our Management
              <br />
              <br />
              Shishu Pre-School is run by Gokulam Educational and Cultural
              Society (GECS). GECS is a not-for-profit Society run by highly
              qualified professionals in various fields like Accountancy,
              Engineering, Medicine, Law, and Academics. GECS aims to promote
              innovative teaching methods to provide Holistic Early Years
              Education. While the day-to-day affairs of the School will be
              managed by the versatile and highly qualified Post Graduates with
              decades of experience in Teaching at very reputed schools, the
              Executive Committee of the Society shall periodically review and
              guide to make the Shishu Pre School a Centre for nurturing young
              minds to scale great heights.
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ManagementComponent;
