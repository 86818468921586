import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div
        class="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6">
              <h3 class="text-white mb-4">Get In Touch</h3>
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3"></i>Kapra, Hyderabad,
                Telangana
              </p>
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3"></i>+91 7207403472
              </p>
              <p class="mb-2" style={{ display: "flex", alignItems: "center" }}>
                <i class="fa fa-envelope me-3"></i>
                shishupreschool23@gmail.com
              </p>
              <div class="d-flex pt-2">
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://instagram.com/shishu_preschool?igshid=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                >
                  <i class="bi bi-instagram"></i>
                </a>
                <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.facebook.com/profile.php?id=100090468397613"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                {/* <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.youtube.com/freewebsitecode"
                >
                  <i class="fab fa-youtube"></i>
                </a> */}
                {/* <a
                  class="btn btn-outline-light btn-social"
                  href="https://www.youtube.com/freewebsitecode"
                >
                  <i class="fab fa-linkedin-in"></i>
                </a> */}
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <h3 class="text-white mb-4">Quick Links</h3>
              <a class="btn btn-link text-white-50" href="/about">
                About Us
              </a>
              <a class="btn btn-link text-white-50" href="/curriculum">
                Curriculum
              </a>
            </div>
            <div class="col-lg-3 col-md-6">
              <h3 class="text-white mb-4">Newsletter</h3>
              <p>
                Sign up for newsletter to get regular updates of our school.
              </p>
              <div
                class="position-relative mx-auto"
                style={{ maxWidth: "400px" }}
              >
                <input
                  class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <iframe
                title="location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.3144875194653!2d78.57171367508542!3d17.49249478341267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9dba7679016d%3A0x31e2a68e377506c1!2sShishu%20Preschool!5e0!3m2!1sen!2sin!4v1683741551132!5m2!1sen!2sin"
                width="300"
                height="300"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="copyright">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                <p>
                  Copyright &copy; 2023 Shishu Preschool | All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
