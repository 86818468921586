// All the title, description texts are included here=

export const schoolName = "Shishu Preschool";
export const navbarItems = {
  HOME: "Home",
  ABOUT_US: "About Us",
  MANAGEMENT: "Management",
  GENESIS: "Genesis",
  CURRICULUM: "Curriculum",
  GALLERY: "Gallery",
  CONTACT_US: "Contact Us",
  JOIN_US: "Join Us",
};
export const MAKEAPPOINTMNET = {
  TITLE: "Make Appointment",
  GAURDIAN_NAME: "Parent Name",
  GAURDIAN_EMAIL: "Parent Email",
  CHILD_NAME: "Child Name",
  CHILD_AGE: "Child Age",
  MESSAGE: "Message",
};
export const ABOUT_US = {
  HEADING: " Learn More About Our Work And Who We Are",
  ABOUT:
    "Welcome to Shishu Pre School, where “Love Meets Innocence”. The School is set up in a very ideal location. A place free of Traffic Congestions and Dust Pollution. At the same time, it is very accessible from the Main Road making it easy for working parents to drop and pick up their child. The school is located at Plot No. 35, Country Estate Colony, Off Saket Main Road, Kapra.",
  DETAILED_DESCRIPTION:
    "The School one of its kind set up keeping in view Holistic Development of the Child and is fully NEP 2020 Compliant. The school is managed by Teachers who are experts in Pre Primary Education. The entire curriculum is uniquely structured through various fun based activity. Considering the little time available for working parents the school aims to promote values and self-confidence at a very young age. The school also provides Day Care Facility and aims to provide After School Activity such as Yoga, Art&Craft, Dance etc.",
};

export const faqs = [
  {
    question: "What is unique about Shishu Pre School?",
    answer:
      "Our innovative curriculum gained from years of experience will nurture the 5 C's  i.e., Curiosity, Cooperation, Confidence, Courage and Compassion in the young minds. We attach importance to health and hygiene.",
  },
  {
    question: "What are the classes in Shishu?",
    answer:
      "We have Play Group, Nursery, LKG and UKG. We also have Day Care facility for Working Parents. All our programs are excellently designed and structured for children from 2 to 6 years involving creative activities.",
  },
  {
    question: "What is special about your curriculum?",
    answer:
      "Our curriculum is holistic to imbibe values and create self-confidence at early age. It is also NEP 2020 compliant. Your child will perform better in whichever career they pursue as they grow.",
  },
  {
    question: "Is your school child friendly?",
    answer:
      "Our school is located in in the Ground Floor with ample play area. It is in a well maintained residential area free from air and sound pollution. Please do visit the school to know more about what we have in store for your Child",
  },
];

export const contactInfo = [
  {
    class: "fa fa-map-marker-alt fa-2x text-primary",
    content: "Kapra, Hyderabad, Telangana",
  },
  {
    class: "fa fa-envelope-open fa-2x text-primary",
    content: "shishupreschool23@gmail.com",
  },
  {
    class: "fa fa-phone-alt fa-2x text-primary",
    content: "Mobile: +91 7207403472",
  },
];

export const SUBMIT = "Submit";

export const BASE_URL = "https://shishu-preschool.onrender.com";
