import React from "react";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { schoolName, navbarItems } from "../../common/constants";
import JoinUsModal from "../JoinUsModal/JoinUs";
import icon from "../../common/images/shishuPreschoolIconNoBg.png";
import "./Header.css";
const { HOME, ABOUT_US, MANAGEMENT, CURRICULUM, GENESIS, JOIN_US } =
  navbarItems;

class Header extends React.Component {
  state = { menu: false, openModal: false, navbar: false };

  toggleMenu = () => {
    this.setState({ ...this.state, menu: !this.state.menu });
  };

  openAppointmentModal = () => {
    this.setState({ ...this.state, openModal: true, clearForm: true });
  };

  handleClose = () => {
    this.setState({ ...this.state, openModal: false, clearForm: true });
  };

  handleActive = () => {
    this.setState({ ...this.state, menu: !this.state.menu });
  };

  handleNavbarPosition = () => {
    window.scrollY >= 50
      ? this.setState({ ...this.state, navbar: true })
      : this.setState({ ...this.state, navbar: false });
  };

  renderNavBar() {
    const show = this.state.menu ? "show" : "";
    const sticky = this.state.navbar ? "sticky" : "";
    return (
      <nav
        className={`navbar navbar-expand-lg bg-white navbar-light fixed-top px-4 px-lg-5 py-lg-0 ${sticky}`}
      >
        <a href="/" className="navbar-brand">
          <h1 className="m-0 text-primary">
            <img
              src={icon}
              style={{ width: "54px", height: "54px", marginRight: "5px" }}
              alt="icon"
            ></img>
            {schoolName}
          </h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          onClick={this.toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={"collapse navbar-collapse " + show}>
          <div className="navbar-nav mx-auto">
            <NavLink
              className="nav-item nav-link"
              exact
              activeClassName="active"
              to="/"
              onClick={this.handleActive}
            >
              {HOME}{" "}
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/about"
              activeClassName="active"
              onClick={this.handleActive}
            >
              {ABOUT_US}{" "}
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/management"
              activeClassName="active"
              onClick={this.handleActive}
            >
              {MANAGEMENT}{" "}
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/genesis"
              activeClassName="active"
              onClick={this.handleActive}
            >
              {GENESIS}{" "}
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/curriculum"
              activeClassName="active"
              onClick={this.handleActive}
            >
              {CURRICULUM}{" "}
            </NavLink>
            {isMobile ? (
              <NavLink
                className="nav-item nav-link"
                to="/appointment"
                activeClassName="active"
                onClick={this.handleActive}
              >
                Make Appointment
              </NavLink>
            ) : null}
          </div>
          <button
            className="btn btn-primary rounded-pill px-3 d-none d-lg-block"
            onClick={this.openAppointmentModal}
          >
            {JOIN_US}
            <i className="fa fa-arrow-right ms-3"></i>
          </button>
        </div>
      </nav>
    );
  }

  render() {
    const { openModal } = this.state;
    window.addEventListener("scroll", this.handleNavbarPosition);
    return (
      <>
        {this.renderNavBar()}
        {openModal && (
          <JoinUsModal openModal={openModal} handleClose={this.handleClose} />
        )}
      </>
    );
  }
}

export default Header;
