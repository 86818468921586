import React from "react";

import balckBoard from "../../common/images/header/empty-blackboard.jpg";
import { Link } from "react-router-dom";

class PageHeader extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div
        class="container-xxxl py-5 page-header position-relative backImg "
        style={{
          backgroundImage: `url(${balckBoard})`,
        }}
      >
        <div class="container py-5">
          <h1 class="display-2 text-white animated slideInDown mb-4">
            {this.props.pagename}
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                {this.props.pagename}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    );
  }
}

export default PageHeader;
