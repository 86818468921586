import React from "react";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import Accordion from "react-bootstrap/Accordion";

import "./Home.css";

import { faqs } from "../../common/constants";

import preNurseryImg from "../../common/images/home/classes/PreNursery.jpeg";
import NurseryImage from "../../common/images/home/classes/Nursery.png";
import LKGIImage from "../../common/images/home/classes/LKG.jpeg";
import UKGImage from "../../common/images/home/classes/UKG.jpg";
import faqImage from "../../common/images/question-mark_noBG.png";

// Gallery images
import schoolEntrance from "../../common/images/home/gallery/school_entrance.jpeg";
import image1 from "../../common/images/home/gallery/gallery_image1.jpeg";
import image2 from "../../common/images/home/gallery/gallery_image2.jpeg";
import image3 from "../../common/images/home/gallery/gallery_image3.jpeg";
import image4 from "../../common/images/home/gallery/gallery_image4.jpeg";
import image5 from "../../common/images/home/gallery/gallery_image5.jpeg";

const images = [schoolEntrance, image1, image2, image3, image4, image5];

class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  renderFaq() {
    return (
      <div className="container-xxxl py-5 faq-section">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 class="mb-3 faq">Frequently Asked Questions</h1>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Accordion defaultActiveKey="0">
                  {faqs.map((faq, index) => {
                    return (
                      <Accordion.Item eventKey={`${index}`}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>{faq.answer}</Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
              <div className="col-md-6 text-center">
                <img className="faqImage" src={faqImage} alt="faq" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderClassesProvided() {
    return (
      <div
        class="container-xxxl py-5"
        style={{ backgroundColor: "antiquewhite" }}
      >
        <div class="container">
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="card" style={{ width: "18rem;" }}>
                <img src={NurseryImage} class="card-img-top" alt="Nursery" />
                <div class="card-body">
                  <h5 class="card-title">Pre-Nursery/Daycare</h5>
                  <p class="card-text">
                    <ul className="ageInfo">
                      <li>Age Group</li>
                      <li>2+ years</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="card" style={{ width: "18rem;" }}>
                <img
                  src={preNurseryImg}
                  class="card-img-top"
                  alt="PreNursery"
                />
                <div class="card-body">
                  <h5 class="card-title">Nursery</h5>
                  <p class="card-text">
                    <ul className="ageInfo">
                      <li>Age Group</li>
                      <li>3 years</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="card" style={{ width: "18rem;" }}>
                <img src={LKGIImage} class="card-img-top" alt="LKG" />
                <div class="card-body">
                  <h5 class="card-title">LKG</h5>
                  <p class="card-text">
                    <ul className="ageInfo">
                      <li>Age Group</li>
                      <li>4 years</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="card" style={{ width: "18rem;" }}>
                <img src={UKGImage} class="card-img-top" alt="UKG" />
                <div class="card-body">
                  <h5 class="card-title">UKG</h5>
                  <p class="card-text">
                    <ul className="ageInfo">
                      <li>Age Group</li>
                      <li>5 years</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderFacilities() {
    return (
      <div class="container-xxl py-5">
        <div class="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 class="mb-3">Why Choose Us?</h1>
            <p>
              Our curriculum is holistic to imbibe values and create
              self-confidence at an early age. It is also NEP 2020 compliant.
              Your child will perform better in whichever career they pursue as
              they grow.
            </p>
          </div>
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="facility-item">
                <div class="facility-icon bg-primary">
                  <span class="bg-primary"></span>
                  <i class="fa fa-light fa-chess fa-3x text-primary"></i>
                  <span class="bg-primary"></span>
                </div>
                <div class="facility-text bg-primary">
                  <h3 class="text-primary mb-3">Indoor Play </h3>
                  <p class="mb-0">
                    Multilevel activities to improve the Eye - Hand
                    Coordination, develop Cognitive Skills and encourage social
                    interaction.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="facility-item">
                <div class="facility-icon bg-success">
                  <span class="bg-success"></span>
                  <i class="fa fa-futbol fa-3x text-success"></i>
                  <span class="bg-success"></span>
                </div>
                <div class="facility-text bg-success">
                  <h3 class="text-success mb-3">Outdoor Play </h3>
                  <p class="mb-0">
                    Variety of Games and activities with Dedicated Sandpit to
                    improve Gross Motor and creative skills and overall physical
                    fitness.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="facility-item">
                <div class="facility-icon bg-warning">
                  <span class="bg-warning"></span>
                  <i class="bi bi-journals" style={{ fontSize: "48px" }}></i>
                  <span class="bg-warning"></span>
                </div>
                <div class="facility-text bg-warning">
                  <h3 class="text-warning mb-3">Kids Library</h3>
                  <p class="mb-0">
                    Colorful and resourceful books to inculcate reading habits.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="facility-item">
                <div class="facility-icon bg-info">
                  <span class="bg-info"></span>
                  <i class="fa fa-chalkboard-teacher fa-3x text-info"></i>
                  <span class="bg-info"></span>
                </div>
                <div class="facility-text bg-info">
                  <h3 class="text-info mb-3">Smart Learning</h3>
                  <p class="mb-0">
                    Activities to achieve Competency based learning
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGallerySection() {
    return (
      <div
        className="container-xxxl py-5"
        style={{ backgroundColor: "blanchedalmond" }}
      >
        <div className="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 class="mb-3">Gallery</h1>
          </div>

          <div className="gallery-row">
            {images.map((image, index) => {
              return (
                <div className="gallery-column">
                  <img
                    className="gallery-images"
                    src={image}
                    alt={`gallery_${index}`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {<CarouselComponent />}
        {this.renderClassesProvided()}
        {this.renderFacilities()}
        {this.renderGallerySection()}
        {this.renderFaq()}
      </>
    );
  }
}

export default Home;
