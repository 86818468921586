import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import MakeAppointmentComponent from "../MakeAppointmentComponent/MakeAppointmentComponent";

import { MAKEAPPOINTMNET } from "../../common/constants";
import "./JoinUs.css";
const { TITLE } = MAKEAPPOINTMNET;

const JoinUsModal = ({ openModal, handleClose }) => {
  return (
    <Modal
      show={openModal}
      onHide={() => handleClose()}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        closeButton
        style={{ backgroundColor: "#fff5f3", border: "0" }}
      >
        <Modal.Title>{TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#fff5f3" }}>
        <MakeAppointmentComponent />
      </Modal.Body>
    </Modal>
  );
};

export default JoinUsModal;
