import { commonrequest } from "./apiSettings";
import { BASE_URL } from "../common/constants";

export const makeAppoinmentReq = async (header, payload) => {
  return await commonrequest(
    "POST",
    `${BASE_URL}/api/makeAppointment`,
    payload,
    header
  );
};
