export const validate = (values) => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const nameRegex = /^[A-Za-z ]+$/;
  const age = Number(values.childAge);
  if (!values.parentName) {
    errors.parentName = "Parent name is required!";
  } else if (!nameRegex.test(values.parentName)) {
    errors.parentName = "Contain special characters!";
  }
  if (!values.parentEmail) {
    errors.parentEmail = "Email is required!";
  } else if (!regex.test(values.parentEmail)) {
    errors.parentEmail = "This is not a valid email format!";
  }
  if (!values.childName) {
    errors.childName = "Child name is required";
  } else if (!nameRegex.test(values.parentName)) {
    errors.childName = "Contain special characters!";
  }
  if (!values.childAge) {
    errors.childAge = "Child age is required";
  } else if (!age) {
    errors.childAge = "Child age should be in numeric";
  } else if (age < 2 || age > 6) {
    errors.childAge = "Please mention child age between 2 to 5 years";
  }
  if (!values.classOption) {
    errors.classOption = "Please choose a class from above options";
  }
  return errors;
};
