import React from "react";
import MakeAppointmentComponent from "../MakeAppointmentComponent/MakeAppointmentComponent";
import { MAKEAPPOINTMNET } from "../../common/constants";
import AppointmentImg from "../../common/images/contactus/appointment.jpg";
const { TITLE } = MAKEAPPOINTMNET;

const ContactUs = () => {
  return (
    <div class="container-xxl py-5">
      <div class="container">
        <div class="bg-light rounded">
          <div class="row g-0">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div class="h-100 d-flex flex-column justify-content-center p-5">
                <h1 class="mb-4">{TITLE}</h1>
                <MakeAppointmentComponent />
              </div>
            </div>
            <div
              class="col-lg-6 wow fadeIn"
              data-wow-delay="0.5s"
              style={{ minHeight: "400px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="position-absolute w-100 h-100 rounded"
                  src={AppointmentImg}
                  alt="Appointment"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
