import React from "react";

import AboutImageOne from "../../common/images/about-1.jpg";
import AboutImageTwo from "../../common/images/about-2.jpg";
import AboutImageThree from "../../common/images/about-3.jpg";
import PageHeader from "../PageHeader/PageHeader";

import { contactInfo } from "../../common/constants";

class AboutUs extends React.Component {
  renderContactDetails() {
    return (
      <div class="container-xxl py-5">
        <div class="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 class="mb-3">Contact Us</h1>
            <p>
              Looking for more information? Whether it is about our school or
              admission process or any other information - we are ready to
              answer any and all queries.
            </p>
          </div>
          <div class="row g-4 mb-5">
            {contactInfo.map((info) => {
              return (
                <div
                  class="col-md-6 col-lg-4 text-center wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div
                    class="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                    style={{ width: "75px", height: "75px" }}
                  >
                    <i class={info.class}></i>
                  </div>
                  <h6>{info.content}</h6>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <>
        <PageHeader pagename="About Us" />
        <div className="container-xxl py-5" id="About">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="mb-4">
                  Learn More About Our Work And Our Cultural Activities
                </h1>
                <p>
                  Welcome to Shishu Pre School, where{" "}
                  <span className="bold-red">“Love Meets Innocence”</span>. The
                  School is set up in a very ideal location. A place free of
                  Traffic Congestions and Dust Pollution. At the same time, it
                  is very accessible from the Main Road making it easy for
                  working parents to drop and pick up their child. The school is
                  located at Plot No. 35, Country Estate Colony, Off Saket Main
                  Road, Kapra.
                </p>
                <p className="mb-4">
                  The School one of its kind set up keeping in view Holistic
                  Development of the Child and is fully NEP 2020 Compliant. The
                  school is managed by Teachers who are experts in Pre Primary
                  Education. The entire curriculum is uniquely structured
                  through various fun based activity. Considering the little
                  time available for working parents the school aims to promote
                  values and self-confidence at a very young age. The school
                  also provides Day Care Facility and aims to provide After
                  School Activity such as Yoga, Art&Craft, Dance etc.
                </p>
              </div>
              <div
                className="col-lg-6 about-img wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <img
                      className="img-fluid w-75 rounded-circle bg-light p-3"
                      src={AboutImageOne}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-6 text-start"
                    style={{ marginTop: "-150px" }}
                  >
                    <img
                      className="img-fluid w-100 rounded-circle bg-light p-3"
                      src={AboutImageTwo}
                      alt=""
                    />
                  </div>
                  <div
                    className="col-6 text-end"
                    style={{ marginTop: "-150px" }}
                  >
                    <img
                      className="img-fluid w-100 rounded-circle bg-light p-3"
                      src={AboutImageThree}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderContactDetails()}
      </>
    );
  }
}

export default AboutUs;
