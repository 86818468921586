import React from "react";
import PageHeader from "../PageHeader/PageHeader";

import assemblyImg from "../../common/images/curriculum/classes-5.jpg";
import numLogicImg from "../../common/images/curriculum/numLogic.jpg";
import GKImg from "../../common/images/curriculum/generalKnowledge.jpg";
import LLImg from "../../common/images/curriculum/language.jpg";
import CreativeExpImg from "../../common/images/curriculum/creativeExp.jpg";
import snackTImeImg from "../../common/images/curriculum/sanckTime.jpg";

class CurriculumComponent extends React.Component {
  render() {
    return (
      <>
        <PageHeader pagename="Curriculum" />
        <div className="container-xxl py-5">
          <div className="container">
            <div
              className="text-center mx-auto mb-5 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ maxWidth: "600px" }}
            >
              <h1 className="mb-3">Our Curriculum</h1>
              <p className="justify">
                The School has Pre-Nursery, Nursery, PP 1 and PP 2 classes. The
                School has developed, based on years of experience and research,
                a Unique Syllabus for various classes, which besides being NEP
                2020 compliant also ensures continuity and coordination in
                improving their skill sets and seamless progression in reaching
                the milestones ahead.
              </p>
            </div>
            <div className="row g-4">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={assemblyImg}
                      alt="Numeracy and Logic"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      Assembly & Circle Time
                    </p>
                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration:</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={GKImg}
                      alt="General Awareness"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      General Awareness
                    </p>
                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration:</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={snackTImeImg}
                      alt="Personal attributes"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      Snacks Time
                    </p>

                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration::</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={LLImg}
                      alt="Language & Literacy"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      Language & Literacy
                    </p>

                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration:</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={numLogicImg}
                      alt="Numeracy and Logic"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      Numeracy & Logic
                    </p>
                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration:</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="classes-item">
                  <div className="bg-light rounded-circle w-75 mx-auto p-3">
                    <img
                      className="img-fluid rounded-circle"
                      src={CreativeExpImg}
                      alt="Creative Expression"
                    />
                  </div>
                  <div className="bg-light rounded p-4 pt-5 mt-n5">
                    <p className="d-block text-center h3 mt-3 mb-4">
                      Creative Expression
                    </p>

                    <div className="row g-1">
                      <div className="col-4">
                        <div className="border-top border-3 border-primary pt-2">
                          <h6 className="text-primary mb-1">Age:</h6>
                          <small>2-5 Years</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-success pt-2">
                          <h6 className="text-success mb-1">Duration:</h6>
                          <small>30 mins</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="border-top border-3 border-warning pt-2">
                          <h6 className="text-warning mb-1">Capacity:</h6>
                          <small>30 Kids</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CurriculumComponent;
