import { BrowserRouter as Router } from "react-router-dom";

import Footer from "./Components/FooterComponent/Footer";
import Header from "./Components/HeaderComponent/Header";
import PreschoolContainer from "./Components/PreSchoolContainer/PreSchoolContainer";

function App() {
  return (
    <div className="App" style={{ backgroundColor: "white" }}>
      <Router>
        <Header />
        <PreschoolContainer />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
