import React from "react";
import PageHeader from "../PageHeader/PageHeader";
import "./GenesisComponent.css";

class GenesisComponent extends React.Component {
  render() {
    return (
      <>
        <PageHeader pagename="Genesis" />
        <div id="paper">
          <div id="pattern">
            <div id="content">
              What we believe in
              <br />
              <br />
              We at Shishu strongly believe that every child is endowed with
              creative ideas which need to be nurtured and our Early Years
              Program tries to do that exactly. Research has confirmed that the
              Brain Development is at its peak in children under five. The
              school will constantly encourage and nurture the imaginative minds
              and ensure that their inquisitiveness and creative ideas blossoms
              into a beautiful flower with the five petals of Curiosity,
              Cooperation, Confidence, Courage and Compassion. All our programs
              are excellently designed and structured for children from 2 years
              to 6 years through various creative activities that will explore
              their imaginative ideas and improve their inter personal traits
              including but not limited to oral and non-verbal communication and
              imbibe values like Empathy and Mutual Respect that will hold them
              in good stead in their long journey ahead.
              <br />
              <br />
              Shishu endeavors to keep itself updated on the innovative Child
              Development Methods which will work as a catalyst to equip every
              little child in building their self-confidence and be a leader
              amongst their Peers. These children will undoubtedly perform
              better in this highly competitive world.
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GenesisComponent;
