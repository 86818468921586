import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { validate } from "../../helpers/validate";
import { makeAppoinmentReq } from "../../services/api";
import { isMobile } from "react-device-detect";
import "./MakeAppointmentComponent.css";
import { MAKEAPPOINTMNET, SUBMIT } from "../../common/constants";
const { GAURDIAN_NAME, GAURDIAN_EMAIL, CHILD_NAME, CHILD_AGE } =
  MAKEAPPOINTMNET;

const MakeAppointmentComponent = () => {
  const intialState = {
    parentName: "",
    parentEmail: "",
    childName: "",
    childAge: "",
    classOption: "",
  };
  const [formValues, setFormValues] = useState(intialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const fillAnotherForm = () => {
    setFormValues(intialState);
    setIsSuccess(false);
    setAlreadyRegistered(false);
  };

  const makeAppointment = async (payload) => {
    let config = {
      "Content-Type": "application/json",
    };
    const response = await makeAppoinmentReq(config, payload);
    if (response.status === 200) {
      setFormValues(intialState);
      setIsSuccess(true);
      setIsSubmit(false);
    }
    if (response.response.status === 409) {
      setIsSubmit(false);
      setAlreadyRegistered(true);
      console.log(response);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (isMobile) {
        window.scrollTo(0, 0);
      }
      makeAppointment(formValues);
    }
  }, [formErrors]);

  if (Object.keys(formErrors).length === 0 && isSubmit) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" variant="secondary" />
      </div>
    );
  }

  if (alreadyRegistered) {
    return (
      <div className="confirmation container">
        <h1 className="confirmation-heading">
          {`Hi ${formValues.parentName}, you have already registered with us, we will get back to you soon.`}
          <button className="form-button" onClick={fillAnotherForm}>
            Fill another form
          </button>
        </h1>
        <div className="queries">
          <p>For any queries, you can reach out us on:</p>
          <p class="mb-2">
            <i class="fa fa-phone-alt me-3"></i>+91 7207403472
          </p>
          <p class="mb-2">
            <i class="bi bi-building me-3"></i>+91 7989991339
          </p>
          <p class="mb-2 email">
            <i class="fa fa-envelope me-3"></i>
            shishupreschool23@gmail.com
          </p>
        </div>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div className="confirmation container">
        <span className="confirmation-icon">
          <i class="fa-sharp fa-solid fa-badge-check"></i>
        </span>
        <h1 className="confirmation-heading">
          Thank you for choosing Shishu Preschool
        </h1>
        <p>We have received your application and will get back to you soon.</p>
        <div className="queries">
          <p>For any queries, you can reach out us on:</p>
          <p class="mb-2">
            <i class="fa fa-phone-alt me-3"></i>+91 7207403472
          </p>
          <p class="mb-2">
            <i class="bi bi-building me-3"></i>+91 7989991339
          </p>
          <p class="mb-2">
            <i class="fa fa-envelope me-3"></i>
            shishupreschool23@gmail.com
          </p>
        </div>
      </div>
    );
  }

  return (
    <form>
      <div className="row g-3">
        <div className="col-sm-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0"
              id="gname"
              placeholder="Gurdian Name"
              name="parentName"
              value={formValues.parentName}
              onChange={handleChange}
            />
            <label for="gname">{GAURDIAN_NAME}</label>
          </div>
          <p className="error">{formErrors.parentName}</p>
        </div>
        <div className="col-sm-6">
          <div className="form-floating">
            <input
              type="email"
              className="form-control border-0"
              id="gmail"
              placeholder="Gurdian Email"
              name="parentEmail"
              value={formValues.parentEmail}
              onChange={handleChange}
            />
            <label for="gmail">{GAURDIAN_EMAIL}</label>
          </div>
          <p className="error">{formErrors.parentEmail}</p>
        </div>
        <div className="col-sm-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0"
              id="cname"
              name="childName"
              placeholder="Child Name"
              value={formValues.childName}
              onChange={handleChange}
            />
            <label for="cname">{CHILD_NAME}</label>
          </div>
          <p className="error">{formErrors.childName}</p>
        </div>
        <div className="col-sm-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control border-0"
              id="cage"
              name="childAge"
              placeholder="Child Age"
              value={formValues.childAge}
              onChange={handleChange}
            />
            <label for="cage">{CHILD_AGE}</label>
          </div>
          <p className="error">{formErrors.childAge}</p>
        </div>
        <div className="radioBtn-container">
          <h5>Select the class your looking for:</h5>
          <div className="class-radio">
            <label>
              <input
                type="radio"
                id="prenursery/daycare"
                name="classOption"
                value="Prenursery/Daycare"
                checked={formValues.classOption === "Prenursery/Daycare"}
                onChange={handleChange}
              />
              <span>Pre-Nursery/Daycare</span>
            </label>
            <label>
              <input
                type="radio"
                id="Nursery"
                name="classOption"
                value="Nursery"
                checked={formValues.classOption === "Nursery"}
                onChange={handleChange}
              />
              <span>Nursery</span>
            </label>
            <label>
              <input
                type="radio"
                id="LKG"
                name="classOption"
                value="LKG"
                checked={formValues.classOption === "LKG"}
                onChange={handleChange}
              />
              <span>LKG</span>
            </label>
            <label>
              <input
                type="radio"
                id="UKG"
                name="classOption"
                value="UKG"
                checked={formValues.classOption === "UKG"}
                onChange={handleChange}
              />
              <span>UKG</span>
            </label>
          </div>
          <p className="error">{formErrors.classOption}</p>
        </div>
        <div className="col-12">
          <button
            className="btn btn-primary w-100 py-3"
            type="button"
            onClick={handleSubmit}
          >
            {SUBMIT}
          </button>
        </div>
      </div>
    </form>
  );
};

export default MakeAppointmentComponent;
