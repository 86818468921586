import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../HomeComponent/Home";
import AboutUs from "../AboutUs/AboutUs";
import ManagementComponent from "../ManagementComponent/ManagementComponent";
import GenesisComponent from "../GenesisComponent/GenesisComponent";
import CurriculumComponent from "../CurriculumComponent/CurriculumComponent";
import ContactUs from "../ContactUs/ContactUsComponent";

class PreschoolContainer extends React.Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/management" element={<ManagementComponent />} />
        <Route path="/genesis" element={<GenesisComponent />} />
        <Route path="/curriculum" element={<CurriculumComponent />} />
        <Route path="/appointment" element={<ContactUs />} />
      </Routes>
    );
  }
}

export default PreschoolContainer;
