import React from "react";
import { Carousel } from "react-responsive-carousel";

import WelcomeCarousel from "../../common/images/carousel/WelcomeCarousel.png";
import admissionOpen from "../../common/images/carousel/shihsu_admissionopen.jpg";
import summerCamp from "../../common/images/carousel/shishuSummerCamp.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselComponent.css";

class CarouselComponent extends React.Component {
  render() {
    return (
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={false}
      >
        <img
          src={WelcomeCarousel}
          alt="carousel-1.jpg"
          style={{ height: "512px" }}
        />
        <img
          src={admissionOpen}
          alt="carousel-2.jpg"
          className="admissionOpen"
          style={{ height: "512px" }}
        />
      </Carousel>
    );
  }
}

export default CarouselComponent;
